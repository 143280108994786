import React, { lazy, Suspense, useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from "./components/ProtectedRoutes";
import LoginPrivateRoute from "./components/LoginPrivateRoute";
import { checkAuth } from "./redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData, getStaticData } from "./redux/features/cms/staticDataSlice";
import Loader from "./components/loader/Loader";
import { LanguageProvider } from "./pages/LanguageContext";

// Lazy loading for routes
const Homepage = lazy(() => import("./pages/Homepage"));
const About_us = lazy(() => import("./pages/About_us"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Contact_us = lazy(() => import("./pages/Contact-us"));
const Gallery = lazy(() => import("./pages/Galleries"));
const Get_quote = lazy(() => import("./pages/Get_quote"));
const Packages = lazy(() => import("./pages/Packages"));
const Services = lazy(() => import("./pages/Services"));
const Dynamic_page = lazy(() => import("./pages/Dynamic_page"));
const Book_service = lazy(() => import("./pages/Book_service"));
const Repair_car_quote = lazy(() => import("./pages/Repair_car_quote"));
const Book_addon = lazy(() => import("./pages/Book_addon"));
const Service_view = lazy(() => import("./pages/service-pages/Service_view"));
const Myaccount = lazy(() => import("./pages/Myaccount"));
const Login = lazy(() => import("./pages/Login"));
const Reset_password = lazy(() => import("./pages/Reset_password"));
const Booking_view = lazy(() => import("./pages/Booking_view"));
const Booking_list = lazy(() => import("./pages/Booking_list"));
const Payment_getway = lazy(() => import("./pages/Payment_getway"));
const Offers = lazy(() => import("./pages/Offers"));
const Landing_page = lazy(() => import("./pages/Landing_page"));
const CareerPage = lazy(() => import("./pages/career/career"));
const JobDetails = lazy(() => import("./pages/career/JobDetails"));
const Countries = lazy(() => import("./pages/Countries"));

function App() {
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState([]);
  const staticData = useSelector((state) => state.staticData);

  const fetchMetaData = useCallback(() => {
    dispatch(getMetaData()).then((res) => {
      setMetaData(res.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStaticData());
    fetchMetaData();
    dispatch(checkAuth()).catch(() => window.location.reload());
  }, [dispatch, fetchMetaData]);

  if (staticData.loading === "PENDING") {
    return <Loader />;
  }

  return (
    <LanguageProvider>
      <div className="App">
        <Router basename="/">
          <ScrollToTop />
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Route without Layout */}
              <Route path="/" element={<Countries />} />

              {/* Routes with Layout */}
              <Route
                path="*"
                element={
                  <Layout {...staticData.data}>
                    <Routes>
                      <Route exact path="/home" element={<Homepage />} />
                      <Route exact path="/about" element={<About_us />} />
                      <Route exact path="/blog" element={<Blogs />} />
                      <Route exact path="/contact-us" element={<Contact_us />} />
                      <Route exact path="/gallery" element={<Gallery />} />
                      <Route exact path="/get-quote" element={<Get_quote />} />
                      <Route exact path="/packages" element={<Packages />} />
                      <Route exact path="/offers" element={<Offers />} />
                      <Route exact path="/services" element={<Services />} />
                      <Route exact path="/service/:id" element={<Service_view />} />

                      <Route exact path="career" element={<CareerPage />} />
                      <Route exact path="job-description/:slug" element={<JobDetails />} />

                      <Route exact path="/page/:slug" element={<Dynamic_page />} />
                      <Route
                        exact
                        path="/landing-page/:language/:campaign/:source"
                        element={<Landing_page />}
                      />
                      <Route exact path="/repair-car-quote" element={<Repair_car_quote />} />

                      {/* myaccount routes */}
                      <Route
                        path="/profile"
                        element={<PrivateRoute><Myaccount /></PrivateRoute>}
                      />
                      <Route
                        path="/payment-getway"
                        element={<PrivateRoute><Payment_getway /></PrivateRoute>}
                      />
                      <Route
                        path="/booking-service/:id"
                        element={<PrivateRoute><Book_service /></PrivateRoute>}
                      />
                      <Route path="/book_addon" element={<Book_addon />} />
                      <Route
                        path="/booking-list"
                        element={<PrivateRoute><Booking_list /></PrivateRoute>}
                      />
                      <Route
                        path="/booking-view/:id"
                        element={<PrivateRoute><Booking_view /></PrivateRoute>}
                      />

                      {/* if user logged in redirect to profile */}
                      <Route
                        path="/auth"
                        element={<LoginPrivateRoute><Login /></LoginPrivateRoute>}
                      />
                      <Route
                        path="/reset-password"
                        element={<LoginPrivateRoute><Reset_password /></LoginPrivateRoute>}
                      />
                    </Routes>
                  </Layout>
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </LanguageProvider>
  );
}

export default App;
